import React from 'react';
import { motion } from 'framer-motion';
import TypingEffect from './typing-effect'; // Assuming TypingEffect component exists
import HackathonCard from './HackathonCard';
import './index.css'; // Assuming a CSS file for custom font styles

const hackathons = [
  { name: '0xDay-HackAthena', date: 'April 6th-7th 2024', city: 'Kerala', hackers: 200 },
  { name: '0xDay-NMIT HACKS', date: 'May 24th 2024', city: 'Bangalore', hackers: 161 },
  { name: '0xDay-RVCE', date: 'June 7th-8th 2024', city: 'Bangalore', hackers: 163 },
  { name: '0xDay-REVA (SHE CODES)', date: 'June 14th-15th 2024', city: 'Bangalore', hackers: 216 },
  { name: '0xDay-Ambition (BIT)', date: 'July 6th-7th 2024', city: 'Bangalore', hackers: 366 },
  { name: '0xDay-Code Cubicle - Geek Room', date: 'July 27th - August 3rd 2024', city: 'Delhi', hackers: 554 },
  { name: '0xDay-Spark the Summer', date: 'July 26th-27th 2024', city: 'Mehsana, Gujarat', hackers: 147 },
  { name: '0xDay-Innofusion', date: 'July 27th-28th 2024', city: 'Kolkata', hackers: 83 },
  { name: '0xDay-Hacker House Goa', date: 'August 4th-9th 2024', city: 'Goa', hackers: 268 },
  { name: '0xDay-UEM Jaipur', date: 'August 9th 2024', city: 'Jaipur', hackers: 336 },
  { name: '0xDay-Codefury', date: 'August 10th 2024', city: 'Bangalore', hackers: 299 },
  { name: '0xDay-IIIT Kalyani', date: 'August 24th-25th 2024', city: 'Kolkata', hackers: 380 },
  { name: '0xDay-Code Tantra', date: 'August 29th-30th 2024', city: 'Mumbai', hackers: 343 },
  { name: '0xDay-Hyperhack', date: 'August 29th-31st 2024', city: 'Pune', hackers: 63 },
  { name: '0xDay-Hack Colossus', date: 'September 13th-14th 2024', city: 'Bangalore', hackers: 145 },
  { name: '0xDay-Hack Vortex', date: 'September 22nd-25th 2024', city: 'Delhi', hackers: 163 },
  { name: '0xDay-Scrollhacks', date: 'September 27th-29th 2024', city: 'Mumbai', hackers: 300 },
  { name: '0xDay-Codestorm', date: 'September 30th - 1st October 2024', city: 'Mumbai', hackers: 232 },
  { name: '0xDay-sdslabs', date: 'October 18th - 20th 2024', city: 'Uttarakhand', hackers: 685 },
  { name: '0xDay-IIIT Dharwad', date: 'October 26th-27th 2024', city: 'Dharwad', hackers: 141 },
];

const LandingPage = () => {
  return (
    <div className="relative min-h-screen overflow-y-auto font-anonymous-pro">
      {/* Background Image */}
      <div
        className="fixed inset-0 bg-cover bg-center"
        style={{
          backgroundImage: "url(/images/bg-5.jpeg)",
          backgroundColor: "rgba(0, 0, 0, 0.91)",
          backgroundBlendMode: "multiply",
        }}
      />

      {/* Main Content */}
      <div className="relative z-10 flex flex-col items-center text-center p-8">
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: [20, -5, 0] }}
          transition={{ duration: 0.4, ease: [0.4, 0.0, 0.2, 1] }}
          className="text-2xl md:text-4xl font-bold lg:text-6xl text-white max-w-7xl leading-relaxed text-center"
        >
          0xDay:{" "}
          <span className="bg-[#52e500] text-black">
            A Hacker's Playground
          </span>
          <div>
          <br /> Online to OnChain
          <br />
          </div>
          <div className="text-[#52e500] text-lg md:text-5xl mt-2">
            <TypingEffect />
          </div>
        </motion.h1>

        {/* New Text for Total Hackers with Animation */}
        <motion.div
          initial={{ opacity: 0, y: 50 }} // Start off invisible and below
          animate={{ opacity: 1, y: 0 }} // Fade in and slide up
          transition={{ 
            duration: 0.6, 
            type: "spring", 
            stiffness: 300, 
            damping: 20, 
            // delay: 0.3 // Delay to sync with other animations
          }}
          className="text-xl md:text-3xl font-bold text-[#52e500] mt-8 mb-4"
        >
          5000+ hackers
        </motion.div>

        {/* Hackathons List */}
        <h2 className="text-3xl md:text-5xl font-bold mt-4 mb-8 text-white">
          0xDay Hosted Hackathons
        </h2>
        <div className="flex flex-wrap justify-center gap-4">
          {hackathons.map((hackathon, index) => (
            <HackathonCard
              key={index}
              name={hackathon.name}
              date={hackathon.date}
              city={hackathon.city}
              hackers={hackathon.hackers}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
