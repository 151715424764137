// src/HackathonCard.jsx
import React from 'react';

const HackathonCard = ({ name, date, city, hackers }) => {
  return (
    <div className="bg-black bg-opacity-40 border border-neonGreen p-4 rounded-xl shadow-lg transition transform hover:scale-110 hover:bg-opacity-60 hover:shadow-2xl hover:shadow-neonGreen w-[80vw] sm:w-[60vw] md:w-[40vw] lg:w-[30vw] xl:w-[20vw] animation-bounceIn">
      <h3 className="text-2xl md:text-3xl font-bold text-neonGreen">{name}</h3>
      <p className="text-lg md:text-xl text-gray-200">{date}</p>
      <p className="text-md md:text-lg text-gray-400">City: {city}</p>
      <p className="text-md md:text-lg text-gray-400">Hackers: {hackers}</p>
    </div>
  );
};

export default HackathonCard;
