import React, { useState, useEffect } from "react";

export const TypingEffect = () => {
  const [typedText, setTypedText] = useState("");
  const [counter, setCounter] = useState(-1);
  const text = "Hack_ > Build_ > Publish_";

  useEffect(() => {
    const textArray = text.split("");

    const interval = setInterval(() => {
      if (counter < text.length) {
        setCounter(counter + 1);
        setTypedText((prevTypedText) => prevTypedText + text.charAt(counter));
      } else {
        textArray.pop();
        setTypedText(textArray.join(""));
        if (textArray.length === 0) {
          setCounter(-1);
        }
      }
    }, 100);

    return () => clearInterval(interval);
  }, [counter, text]);

  return (
    <div>
      <h1>
        Code_ &gt;&nbsp;<span className="">{typedText}</span>
      </h1>
    </div>
  );
};
export default TypingEffect;